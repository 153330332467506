import React, { useState } from 'react'
import {
  Box,
  Container,
  FormControl,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
  Typography,
} from '@mui/material'
import { PaypalButtonWrapper } from '../../components/paypal'

export const HomePage = () => {
  const [name, setName] = useState('')
  const [nameError, setNameError] = useState(false)
  const [amount, setAmount] = useState(3.5)
  const [amountError, setAmountError] = useState(false)
  const [message, setMessage] = useState('')
  const [messageError, setMessageError] = useState(false)
  const nameLength = 30
  const messageLength = 1000
  const [donationResult, setDonationResult] = useState(false)

  const handleNameChange = handledName => {
    if (handledName.length > nameLength) {
      setNameError(true)
    } else {
      setNameError(false)
    }
    setName(handledName)
  }

  const handleAmountChange = handledAmount => {
    if (handledAmount <= 9999999) {
      if (isNaN(Number(handledAmount)) || handledAmount < 0) {
        setAmount(0)
      } else {
        setAmount(handledAmount)
      }
      setAmountError(false)
    } else {
      setAmount(9999999)
      setAmountError(true)
    }
  }

  const handleMessageChange = handledMessage => {
    if (handledMessage.length > messageLength) {
      setMessageError(true)
    } else {
      setMessageError(false)
    }
    setMessage(handledMessage)
  }

  return (
    <Container>
      {donationResult?.success && (
        <Typography color="green" variant="p" component="p" gutterBottom>
          {donationResult.success}
        </Typography>
      )}
      {donationResult?.errors?.name && (
        <Typography color="red" variant="p" component="p" gutterBottom>
          {donationResult.errors.name}
        </Typography>
      )}
      {donationResult?.errors?.message && (
        <Typography color="red" variant="p" component="p" gutterBottom>
          {donationResult.errors.message}
        </Typography>
      )}
      <Box
        sx={{
          height: 'calc(100vh - 64px)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box component="form" sx={{ display: 'flex', flexWrap: 'wrap' }}>
          <TextField
            label="Name"
            sx={{ mb: 2 }}
            value={name}
            error={nameError}
            onChange={e => handleNameChange(e.target.value)}
            helperText={
              nameError &&
              `Field restricted to ${nameLength} Characters, please shorten name.`
            }
          />
          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel htmlFor="outlined-adornment-amount">Amount</InputLabel>
            <OutlinedInput
              id="outlined-adornment-amount"
              value={amount}
              type="text"
              error={amountError}
              inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
              onChange={e => handleAmountChange(e.target.value)}
              startAdornment={
                <InputAdornment position="start">£</InputAdornment>
              }
              label="Amount"
            />
            {amount < 3 && (
              <Typography variant="p" component="p" gutterBottom>
                Only donations of 3 or more pounds allow for text to speech.
              </Typography>
            )}
            {amountError && (
              <Typography color="red" variant="p" component="p" gutterBottom>
                Maximum donation amount exceeded, please lower donation amount.
              </Typography>
            )}
          </FormControl>
          {amount >= 3 && (
            <FormControl fullWidth sx={{ mb: 2 }}>
              <TextField
                sx={{ mb: 2 }}
                id="outlined-multiline-flexible"
                label="Enter Message"
                multiline
                value={message}
                error={messageError}
                onChange={e => handleMessageChange(e.target.value)}
                helperText={
                  messageError &&
                  `Field restricted to ${messageLength} Characters, please shorten message.`
                }
              />
            </FormControl>
          )}
          <PaypalButtonWrapper
            callback={setDonationResult}
            currency="GBP"
            disabled={nameError || amountError || messageError}
            name={name}
            amount={amount}
            message={message}
            showSpinner={false}
          />
        </Box>
      </Box>
    </Container>
  )
}
